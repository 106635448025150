import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterSection = styled('footer')`
  background: rgb(241, 242, 243);
  padding: 2.5rem 0;

  @media (max-width: 768px) {
    padding: 1.5rem 0;
    text-align: center; /* Center align content on smaller screens */
  }
`;

export const CustomImg = styled.img`
  width: 100px; /* Adjust the width */
  height: 60px; /* Explicit height to prevent shifting */
  object-fit: contain; /* Ensures the image scales properly */

  @media only screen and (max-width: 768px) {
    width: 80px;
    height: 35px;
  }

  @media only screen and (max-width: 576px) {
    width: 70px;
    height: 30px;
  }
`;

export const Title = styled('h4')`
  font-size: 22px;
  text-transform: capitalize;
  color: #000;

  @media (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }
`;

export const Extra = styled('section')`
  background: rgb(241, 242, 243);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    padding-bottom: 1.5rem;
  }
`;

export const LogoContainer = styled('div')`
  display: flex;
  position: relative;

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

export const Para = styled('div')`
  color: #000;
  font-size: 14px;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterContainer = styled('div')`
  max-width: 510px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  a {
    &:hover,
    &:active,
    &:focus {
      transform: scale(1.1);
    }
  }

  /* Remove this block to ensure horizontal alignment remains */
  @media (max-width: 768px) {
    flex-direction: row; /* Ensure the social links remain in a row */
    justify-content: center; /* Center them on smaller screens */
    align-items: center;

    a {
      margin: 0 10px; /* Add horizontal spacing between social icons */
    }
  }

  @media (max-width: 414px) {
    a {
      margin: 0 5px; /* Adjust spacing on smaller devices */
    }
  }
`;

export const Language = styled('h4')`
  font-size: 22px;
  text-transform: capitalize;
  color: #000;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
`;

export const Label = styled('label')`
  font-size: 22px;
  text-transform: capitalize;
  color: #000;
  display: block;
  margin-bottom: 2rem;
  font-family: 'Motiva Sans Bold', serif;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 1rem;
  }
`;

export const LanguageSwitch = styled('div')`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    margin: 0 5px;
  }
`;

export const LanguageSwitchContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 85px;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;
