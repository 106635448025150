import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { Button } from '../../common/Button';

export const NoShadowButton = styled(Button)`
  box-shadow: none;
  font-size: 0.9rem; /* Smaller font size */
  padding: 6px 10px; /* Smaller padding */
`;

export const StyledRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;

  @media only screen and (max-width: 768px) {
    padding: 0 140px;
  }

  @media only screen and (max-width: 576px) {
    padding: 0 30px;
  }
`;

export const HeaderSection = styled.header`
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 8px 15px rgb(23 31 114 / 4%); /* Default shadow for larger screens */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  transition: background-color 0.3s ease-in-out;

  @media only screen and (max-width: 768px) {
    box-shadow: none; /* Remove shadow on smaller screens */
    border-bottom: 1px solid #e0e0e0; /* Add gray border on smaller screens */
  }
`;

export const CustomImg = styled.img`
  width: 100px; /* Adjust the width */
  height: 60px; /* Explicit height to prevent shifting */
  object-fit: contain; /* Ensures the image scales properly */

  @media only screen and (max-width: 768px) {
    width: 80px;
    height: 35px;
  }

  @media only screen and (max-width: 576px) {
    width: 70px;
    height: 30px;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

export const NavLink = styled.div`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1rem;
  color: #000;
  transition: color 0.2s ease-in;
  margin: 0 2rem;

  &:hover {
    color: rgb(255, 130, 92);
  }

  @media only screen and (max-width: 1024px) {
    margin: 0 1rem;
  }

  @media only screen and (max-width: 768px) {
    margin: 1rem 0;
    font-size: 0.9rem;
  }
`;

export const Burger = styled.div`
  display: none;

  @media only screen and (max-width: 890px) {
    display: block;
    cursor: pointer;
    padding: 10px;
    svg {
      fill: #000;
    }
  }
`;

export const NotHidden = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled.h5`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const CloseIcon = styled(CloseOutlined)`
  font-size: 22px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Span = styled.span`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 130, 92);
    text-decoration: underline;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 60px; /* Aligns directly below the header */
  width: 100%;
  background-color: white;
  border-top: 1px solid #e0e0e0; /* Add a subtle border to separate it from the header */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  z-index: 999;
  animation: slideDown 0.3s ease-in-out;

  @keyframes slideDown {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 60px; /* Starts below the header */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Darken the background */
  backdrop-filter: blur(5px); /* Blur the background */
  z-index: 998; /* Behind the dropdown */
`;

export const MainContentWrapper = styled.div<{ dropdownVisible: boolean }>`
  transition: filter 0.3s ease;
  ${({ dropdownVisible }) =>
    dropdownVisible
      ? `
      filter: blur(5px); /* Blur the content when dropdown is visible */
      `
      : `
      filter: none;
    `}
`;
