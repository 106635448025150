import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import i18n from "i18next";
import Container from "../../common/Container";
import {
  FooterSection,
  LogoContainer,
  CustomImg,
  Para,
  FooterContainer,
  Language,
  NavLink,
  Extra,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
  alt: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src, alt }: SocialLinkProps) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      key={src}
      aria-label={alt}
    >
      <img src={src} alt={alt} width="30px" height="30px" />
    </a>
  );

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between" align="middle">
            <Col lg={12} md={12} sm={24} xs={24}>
              <Language>{t("Address")}</Language>
              <Para>Building 60, 23 College Walk</Para>
              <Para>3168, Clayton</Para>
              <Para>Victoria</Para>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>

            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <CustomImg src="/FocaLogo.png" alt="logo" />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://x.com/FocaHQ"
                src="/img/icons/x.png"
                alt="Twitter"
              />
              <SocialLink
                href="https://www.instagram.com/focahq"
                src="/img/icons/instagram.png"
                alt="Instagram"
              />
              <SocialLink
                href="https://discord.com/invite/Rhx9Cey2Aj"
                src="/img/icons/discord.png"
                alt="Discord"
              />
              <SocialLink
                href="https://www.youtube.com/@FocaHQ"
                src="/img/icons/youtube.png"
                alt="YouTube"
              />
              <SocialLink
                href="https://www.tiktok.com/@foca.hq"
                src="/img/icons/tiktok.png"
                alt="TikTok"
              />
              <SocialLink
                href="https://www.twitch.tv/foca_prod"
                src="/img/icons/twitch.png"
                alt="Twitch"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
