import { useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation, TFunction } from "react-i18next";
import {
  StyledContainer,
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomNavLinkSmall,
  Span,
  StyledRow,
  NoShadowButton,
  CustomImg,
  Outline,
  CloseIcon,
  DropdownMenu,
  ModalOverlay,
  MainContentWrapper,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const history = useHistory();

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const navigateTo = (path: string) => {
    history.push(path);
    setDropdownVisible(false);
  };

  const MenuItem = () => (
    <>
      <CustomNavLinkSmall onClick={() => navigateTo("/about")}>
        <Span>{t("About Us")}</Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall onClick={() => navigateTo("/features")}>
        <Span>{t("Features")}</Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall onClick={() => navigateTo("/blog")}>
        <Span>{t("Blog")}</Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall onClick={() => navigateTo("/support")}>
        <Span>{t("Support")}</Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall style={{ width: "90px" }}>
        <a href="https://app.focahq.com" style={{ textDecoration: "none" }}>
          <Span>
            <NoShadowButton>{t("Login")}</NoShadowButton>
          </Span>
        </a>
      </CustomNavLinkSmall>
    </>
  );

  return (
    <>
      <HeaderSection>
        <StyledContainer>
          <StyledRow justify="center">
            <LogoContainer to="/" aria-label="homepage">
              <CustomImg src="/FocaLogo.png" alt="logo" />
            </LogoContainer>
            <NotHidden>
              <MenuItem />
            </NotHidden>
            <Burger onClick={toggleDropdown}>
              {dropdownVisible ? <CloseIcon /> : <Outline />}
            </Burger>
          </StyledRow>
        </StyledContainer>
      </HeaderSection>

      {dropdownVisible && (
        <>
          <ModalOverlay onClick={closeDropdown} />
          <DropdownMenu>
            <MenuItem />
          </DropdownMenu>
        </>
      )}

      <MainContentWrapper dropdownVisible={dropdownVisible}></MainContentWrapper>
    </>
  );
};

export default withTranslation()(Header);
